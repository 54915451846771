import { defineStore } from 'pinia';

const importFromLocalStorage = () => {
    const storedFlags = localStorage.getItem('featureFlags');
    if (storedFlags) {
        return JSON.parse(storedFlags);
    }

    return {};
};

export const useToggleStore = defineStore('toggles', {
    state: () => {
        const defaultFlags = {
            "Practitioners 2.0": false,
            "Facilities 2.0": false,
            "Member MA Assignment Dropdown": false,
        };
        return {
            featureFlags: {
                ...defaultFlags,
                ...importFromLocalStorage(),
            },
            defaultFlags,
        };
    },
    getters: {
        isFlagEnabled: (state) => (flagName) => {
            return state.featureFlags[flagName] || false;
        },
        getAsArray: (state) => () => {
            return Object.entries(state.featureFlags).map(([key, value]) => ({ key, value }));
        },
    },
    actions: {
        setFeatureFlag(flagName, value) {
            this.featureFlags[flagName] = value;
            localStorage.setItem('featureFlags', JSON.stringify(this.featureFlags));
        },
        toggleFeatureFlag(flagName) {
            this.featureFlags[flagName] = !this.featureFlags[flagName];
            localStorage.setItem('featureFlags', JSON.stringify(this.featureFlags));
        },
        resetFeatureFlags() {
            localStorage.setItem('featureFlags', JSON.stringify(this.defaultFlags));
            this.featureFlags = { ...this.defaultFlags };
        },
    },
});